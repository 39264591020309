import gql from 'graphql-tag';

export interface ListLocationsResponse {
  locations: Array<{
    locationId: string;
    title: string;
  }>;
}

export const LIST_LOCATIONS = gql`
  {
    locations {
      locationId
      title
    }
  }
`;

export interface ListTripsResponse {
  trips: Array<{
    tripId: string;
    startDate: string;
    location: {
      title: string;
    };
  }>;
}

export const LIST_TRIPS = gql`
  query trips($filter: TripsFilter) {
    trips(filter: $filter) {
      tripId
      startDate
      location {
        title
      }
    }
  }
`;
