import { Button, Form, message } from 'antd';
import { useFormik } from 'formik';
import * as React from 'react';
import { useMutation } from 'react-apollo-hooks';
import { RouteComponentProps } from 'react-router-dom';
import Alert from '../../components/Alert';
import Layout from '../../components/Layout';
import links from '../../lib/links';
import CouponForm from './components/CouponForm';
import { FormProvider } from './components/Form';
import { CREATE_COUPON, CreateCouponResponse } from './Create.graphql';

const formItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

interface Values {}

interface Props extends RouteComponentProps {}

function Create(props: Props) {
  const [createCoupon, { loading, error }] = useMutation<CreateCouponResponse>(CREATE_COUPON);

  const formik = useFormik<Values>({
    initialValues: {
      rules: [],
      disabled: false,
      global: false
    },
    onSubmit: async input => {
      try {
        const { data } = await createCoupon({ variables: { input } });
        message.success('Coupon create successfully!');
        if (data) {
          props.history.push(links.editCoupon(data.createCoupon.couponId));
        }
      } catch (err) {
        console.error(err);
        message.error('Coupon create failed!');
      }
    }
  });

  return (
    <Layout>
      <FormProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <CouponForm />

          <Form.Item {...formItemLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>

          <Alert error={error} />
        </form>
      </FormProvider>
    </Layout>
  );
}

export default Create;
