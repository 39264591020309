import { Button, Form } from 'antd';
import { message } from 'antd/es';
import { useFormik } from 'formik';
import * as React from 'react';
import { useMutation } from 'react-apollo-hooks';
import { RouteComponentProps } from 'react-router-dom';
import Alert from '../../components/Alert';
import Layout from '../../components/Layout';
import LocationForm from '../../components/LocationForm/LocationForm';
import links from '../../lib/links';
import { FormProvider } from '../Coupon/components/Form';
import { CREATE_LOCATION, CreateLocationResponse } from './Create.graphql';

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

interface Props extends RouteComponentProps {}

function Create(props: Props) {
  const [createLocation, { loading, error }] = useMutation<CreateLocationResponse>(CREATE_LOCATION);

  const formik = useFormik<any>({
    initialValues: {},
    onSubmit: async values => {
      try {
        const { data } = await createLocation({
          variables: {
            input: values
          }
        });
        message.success('Location create successfully!');
        if (data) {
          props.history.push(links.editLocation(data.createLocation.locationId));
        }
      } catch (err) {
        message.error('Location create failed!');
      }
    }
  });

  return (
    <Layout>
      <FormProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <LocationForm />

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>

          <Alert error={error} />
        </form>
      </FormProvider>
    </Layout>
  );
}

export default Create;
