import gql from 'graphql-tag';

export interface CreateCouponResponse {
  createCoupon: {
    couponId: string;
  };
}

export const CREATE_COUPON = gql`
  mutation createCoupon($input: CreateCouponInput!) {
    createCoupon(input: $input) {
      couponId
    }
  }
`;
