import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};






export type IAddress = {
  line1?: Maybe<Scalars['String']>,
  line2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  postalCode?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IAddressInput = {
  line1: Scalars['String'],
  line2?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  region: Scalars['String'],
  postalCode: Scalars['String'],
  country: Scalars['String'],
};

export type IAddSheetInput = {
  locationId: Scalars['String'],
  tripId: Scalars['String'],
};

export type IAppendBookingToSpreadsheet = {
  bookingId: Scalars['ID'],
};

export type IApplyCouponInput = {
  couponId: Scalars['String'],
  sku: Scalars['String'],
};

export type IApplyCouponResponse = {
  discountAmount?: Maybe<Scalars['Float']>,
};

export type IBooking = {
  bookingId: Scalars['ID'],
  tripId: Scalars['ID'],
  returnCustomer?: Maybe<Scalars['Boolean']>,
  roomArrangement?: Maybe<IRoomArrangement>,
  friendName?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  status?: Maybe<IBookingStatus>,
  travelerCount?: Maybe<Scalars['Int']>,
  manuallyCreated?: Maybe<Scalars['Boolean']>,
  travelers?: Maybe<Array<ITraveler>>,
  changelogs?: Maybe<Array<IBookingChangelog>>,
  order?: Maybe<IOrder>,
  trip?: Maybe<ITrip>,
};

export type IBookingChangelog = {
  eventId: Scalars['ID'],
  action?: Maybe<Scalars['String']>,
  rowData?: Maybe<Scalars['String']>,
  changedFields?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
};

export type IBookingOrder = {
  bookingId?: Maybe<Scalars['ID']>,
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Float']>,
  sku?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IBookingPage = {
  nextPageToken?: Maybe<Scalars['String']>,
  bookings?: Maybe<Array<IBooking>>,
};

export enum IBookingStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  CancelledRefunded = 'CANCELLED_REFUNDED',
  CancelledHeld = 'CANCELLED_HELD'
}

export type ICountry = {
  code?: Maybe<Scalars['String']>,
  alpha2?: Maybe<Scalars['String']>,
  alpha3?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  region?: Maybe<Scalars['String']>,
  subRegion?: Maybe<Scalars['String']>,
  intermediateRegion?: Maybe<Scalars['String']>,
  countryCode?: Maybe<Scalars['String']>,
  regionCode?: Maybe<Scalars['String']>,
  subRegionCode?: Maybe<Scalars['String']>,
  intermediateRegionCode?: Maybe<Scalars['String']>,
};

export type ICoupon = {
  couponId: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRule>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  deletedTime?: Maybe<Scalars['String']>,
  locations?: Maybe<Array<Maybe<ILocation>>>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
};

export type ICouponFilter = {
  global?: Maybe<Scalars['Boolean']>,
};

export type ICouponRule = {
  type?: Maybe<ICouponRuleType>,
  value?: Maybe<Scalars['Float']>,
  conditions?: Maybe<Array<Maybe<ICouponRuleCondition>>>,
};

export type ICouponRuleCondition = {
  field?: Maybe<Scalars['String']>,
  operator?: Maybe<Scalars['String']>,
  value?: Maybe<Scalars['Float']>,
};

export type ICouponRuleConditionInput = {
  field: Scalars['String'],
  operator: Scalars['String'],
  value: Scalars['Float'],
};

export type ICouponRuleInput = {
  type: ICouponRuleType,
  value: Scalars['Float'],
  conditions?: Maybe<Array<Maybe<ICouponRuleConditionInput>>>,
};

export enum ICouponRuleType {
  Amount = 'amount',
  Percentage = 'percentage'
}

export type ICreateBookingInput = {
  tripId: Scalars['ID'],
  returnCustomer?: Maybe<Scalars['Boolean']>,
  travelers?: Maybe<Array<Maybe<ITravelerInput>>>,
  roomArrangement: IRoomArrangement,
  friendName?: Maybe<Scalars['String']>,
  status?: Maybe<IBookingStatus>,
  idempotencyKey?: Maybe<Scalars['String']>,
  acceptTerms?: Maybe<Scalars['Boolean']>,
  acceptCancellation?: Maybe<Scalars['Boolean']>,
  order?: Maybe<IOrderInput>,
};

export type ICreateCouponInput = {
  couponId: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRuleInput>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ICreateLocationInput = {
  country: Scalars['String'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ICreateOrderPriceAdjustmentInput = {
  orderId: Scalars['ID'],
  amount: Scalars['Int'],
  note: Scalars['String'],
};

export type ICreateTripInput = {
  locationId: Scalars['ID'],
  startDate: Scalars['String'],
  endDate: Scalars['String'],
  maxSeats: Scalars['Int'],
  earlyPrice: Scalars['Int'],
  depositPrice?: Maybe<Scalars['Int']>,
  fullPrice: Scalars['Int'],
  detailsUrl?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export enum IGender {
  Male = 'male',
  Female = 'female'
}

export type IGetOrderPreviewInput = {
  quantity: Scalars['Int'],
  sku: Scalars['ID'],
  couponId?: Maybe<Scalars['ID']>,
};

export type ILinkSalesDumpSpreadsheetInput = {
  spreadsheetId: Scalars['String'],
};

export type ILocation = {
  locationId: Scalars['ID'],
  country?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  cntry?: Maybe<ICountry>,
  coupons?: Maybe<Array<Maybe<ICoupon>>>,
  spreadsheet?: Maybe<ISpreadsheet>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
};

export type IMutation = {
  createBooking?: Maybe<IBooking>,
  updateBooking?: Maybe<IBooking>,
  updateTraveler?: Maybe<ITraveler>,
  createCoupon?: Maybe<ICoupon>,
  updateCoupon?: Maybe<ICoupon>,
  deleteCoupon: Scalars['Boolean'],
  createLocation?: Maybe<ILocation>,
  deleteLocation?: Maybe<Scalars['Boolean']>,
  updateLocation?: Maybe<ILocation>,
  applyCoupon?: Maybe<IApplyCouponResponse>,
  getOrderPreview?: Maybe<IOrderPreview>,
  updateTripSKU?: Maybe<ITripSku>,
  createOrderPriceAdjustment?: Maybe<IOrderPriceAdjustment>,
  linkSalesDumpSpreadsheet?: Maybe<Scalars['Boolean']>,
  addSheet?: Maybe<Scalars['Boolean']>,
  shareSpreadsheet?: Maybe<Scalars['Boolean']>,
  appendBookingToSpreadsheet?: Maybe<Scalars['Boolean']>,
  createTrip?: Maybe<ITrip>,
  updateTrip?: Maybe<ITrip>,
  updatePolicy: Scalars['Boolean'],
};


export type IMutationCreateBookingArgs = {
  input: ICreateBookingInput
};


export type IMutationUpdateBookingArgs = {
  input: IUpdateBookingInput
};


export type IMutationUpdateTravelerArgs = {
  input: IUpdateTravelerInput
};


export type IMutationCreateCouponArgs = {
  input: ICreateCouponInput
};


export type IMutationUpdateCouponArgs = {
  input: IUpdateCouponInput
};


export type IMutationDeleteCouponArgs = {
  couponId: Scalars['ID']
};


export type IMutationCreateLocationArgs = {
  input: ICreateLocationInput
};


export type IMutationDeleteLocationArgs = {
  locationId: Scalars['ID']
};


export type IMutationUpdateLocationArgs = {
  input?: Maybe<IUpdateLocationInput>
};


export type IMutationApplyCouponArgs = {
  input: IApplyCouponInput
};


export type IMutationGetOrderPreviewArgs = {
  input: IGetOrderPreviewInput
};


export type IMutationUpdateTripSkuArgs = {
  input: IUpdateTripSkuInput
};


export type IMutationCreateOrderPriceAdjustmentArgs = {
  input: ICreateOrderPriceAdjustmentInput
};


export type IMutationLinkSalesDumpSpreadsheetArgs = {
  input?: Maybe<ILinkSalesDumpSpreadsheetInput>
};


export type IMutationAddSheetArgs = {
  input: IAddSheetInput
};


export type IMutationShareSpreadsheetArgs = {
  input: IShareSpreadsheetInput
};


export type IMutationAppendBookingToSpreadsheetArgs = {
  input: IAppendBookingToSpreadsheet
};


export type IMutationCreateTripArgs = {
  input: ICreateTripInput
};


export type IMutationUpdateTripArgs = {
  input: IUpdateTripInput
};


export type IMutationUpdatePolicyArgs = {
  input: IUpdatePolicyInput
};

export type IOrder = {
  bookingId?: Maybe<Scalars['ID']>,
  orderId?: Maybe<Scalars['ID']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  sku?: Maybe<Scalars['String']>,
  referralCode?: Maybe<Scalars['String']>,
  chargeId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
  details?: Maybe<IOrderDetails>,
  couponIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  priceAdjustments?: Maybe<Array<Maybe<IOrderPriceAdjustment>>>,
};

export type IOrderCoupon = {
  orderId?: Maybe<Scalars['ID']>,
  couponId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['String']>,
};

export type IOrderDetails = {
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
};

export type IOrderInput = {
  token: Scalars['String'],
  sku: Scalars['String'],
  couponId?: Maybe<Scalars['String']>,
};

export type IOrderItemPreview = {
  type?: Maybe<Scalars['String']>,
  amount?: Maybe<Scalars['Int']>,
  description?: Maybe<Scalars['String']>,
  parent?: Maybe<Scalars['String']>,
  currency?: Maybe<Scalars['String']>,
  quantity?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
};

export type IOrderPreview = {
  amount?: Maybe<Scalars['Int']>,
  items?: Maybe<Array<Maybe<IOrderItemPreview>>>,
};

export type IOrderPriceAdjustment = {
  id: Scalars['ID'],
  orderId?: Maybe<Scalars['ID']>,
  amount?: Maybe<Scalars['Int']>,
  note?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IPassport = {
  number?: Maybe<Scalars['String']>,
  expiration?: Maybe<Scalars['String']>,
  country?: Maybe<Scalars['String']>,
};

export type IPassportInput = {
  number: Scalars['String'],
  expiration: Scalars['String'],
  country: Scalars['String'],
};

export type IQuery = {
  bookingPage?: Maybe<IBookingPage>,
  booking?: Maybe<IBooking>,
  countBooking?: Maybe<Scalars['Int']>,
  countTraveler?: Maybe<Scalars['Int']>,
  bookingChangelogs?: Maybe<Array<Maybe<IBookingChangelog>>>,
  coupons?: Maybe<Array<Maybe<ICoupon>>>,
  coupon?: Maybe<ICoupon>,
  locations?: Maybe<Array<Maybe<ILocation>>>,
  location?: Maybe<ILocation>,
  listTripSKUs?: Maybe<Array<Maybe<ITripSku>>>,
  getBookingOrder?: Maybe<IBookingOrder>,
  getTripProduct?: Maybe<Scalars['String']>,
  listOrderCoupons?: Maybe<Array<Maybe<Scalars['ID']>>>,
  orderCouponsV2?: Maybe<Array<Maybe<IOrderCoupon>>>,
  order?: Maybe<IOrder>,
  orderDetails?: Maybe<IOrderDetails>,
  orderPriceAdjustments?: Maybe<Array<Maybe<IOrderPriceAdjustment>>>,
  spreadsheet?: Maybe<ISpreadsheet>,
  trips?: Maybe<Array<Maybe<ITrip>>>,
  trip?: Maybe<ITrip>,
  policy: Scalars['String'],
};


export type IQueryBookingPageArgs = {
  pageToken?: Maybe<Scalars['String']>,
  pageSize?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type IQueryBookingArgs = {
  bookingId: Scalars['ID']
};


export type IQueryCountBookingArgs = {
  tripId: Scalars['ID'],
  status?: Maybe<Array<Maybe<IBookingStatus>>>
};


export type IQueryCountTravelerArgs = {
  tripId: Scalars['ID'],
  status?: Maybe<Array<Maybe<IBookingStatus>>>
};


export type IQueryBookingChangelogsArgs = {
  bookingId: Scalars['ID']
};


export type IQueryCouponsArgs = {
  filter?: Maybe<ICouponFilter>
};


export type IQueryCouponArgs = {
  couponId: Scalars['ID']
};


export type IQueryLocationArgs = {
  locationId: Scalars['ID']
};


export type IQueryListTripSkUsArgs = {
  tripId: Scalars['ID']
};


export type IQueryGetBookingOrderArgs = {
  bookingId: Scalars['ID']
};


export type IQueryGetTripProductArgs = {
  tripId: Scalars['ID']
};


export type IQueryListOrderCouponsArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderCouponsV2Args = {
  orderId: Scalars['ID']
};


export type IQueryOrderArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderDetailsArgs = {
  orderId: Scalars['ID']
};


export type IQueryOrderPriceAdjustmentsArgs = {
  orderId: Scalars['ID']
};


export type IQuerySpreadsheetArgs = {
  locationId: Scalars['String']
};


export type IQueryTripsArgs = {
  filter?: Maybe<ITripsFilter>
};


export type IQueryTripArgs = {
  tripId: Scalars['ID']
};

export enum IRoomArrangement {
  Solo = 'solo',
  Friend = 'friend',
  Couple = 'couple'
}

export type IShareSpreadsheetInput = {
  spreadsheetId: Scalars['String'],
  emails?: Maybe<Array<Maybe<Scalars['String']>>>,
  domain?: Maybe<Scalars['String']>,
};

export type ISpreadsheet = {
  spreadsheetId: Scalars['ID'],
  locationId: Scalars['ID'],
  url?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type ITraveler = {
  travelerId?: Maybe<Scalars['ID']>,
  firstName?: Maybe<Scalars['String']>,
  lastName?: Maybe<Scalars['String']>,
  birthday?: Maybe<Scalars['String']>,
  gender?: Maybe<IGender>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  passport?: Maybe<IPassport>,
  address?: Maybe<IAddress>,
};

export type ITravelerInput = {
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  birthday: Scalars['String'],
  gender: IGender,
  email: Scalars['String'],
  phone: Scalars['String'],
  passport?: Maybe<IPassportInput>,
  address: IAddressInput,
};

export type ITrip = {
  tripId: Scalars['ID'],
  locationId: Scalars['ID'],
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  maxSeats?: Maybe<Scalars['Int']>,
  detailsUrl?: Maybe<Scalars['String']>,
  active?: Maybe<Scalars['Boolean']>,
  archived?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
  seatsTaken?: Maybe<Scalars['Int']>,
  bookings?: Maybe<Array<Maybe<IBooking>>>,
  location?: Maybe<ILocation>,
  skus?: Maybe<Array<Maybe<ITripSku>>>,
  productId?: Maybe<Scalars['ID']>,
};

export type ITripsFilter = {
  locationId?: Maybe<Scalars['ID']>,
  archived?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
};

export type ITripSku = {
  tripId?: Maybe<Scalars['ID']>,
  sku?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>,
  price?: Maybe<Scalars['Int']>,
  active?: Maybe<Scalars['Boolean']>,
  livemode?: Maybe<Scalars['Boolean']>,
  currency?: Maybe<Scalars['String']>,
  createTime?: Maybe<Scalars['String']>,
  updateTime?: Maybe<Scalars['String']>,
};

export type IUpdateBookingInput = {
  bookingId: Scalars['ID'],
  tripId: Scalars['ID'],
  returnCustomer: Scalars['Boolean'],
  roomArrangement: IRoomArrangement,
  status: IBookingStatus,
  friendName?: Maybe<Scalars['String']>,
};

export type IUpdateCouponInput = {
  couponId: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  startTime?: Maybe<Scalars['String']>,
  endTime?: Maybe<Scalars['String']>,
  maxRedemptions?: Maybe<Scalars['Int']>,
  global?: Maybe<Scalars['Boolean']>,
  disabled?: Maybe<Scalars['Boolean']>,
  rules?: Maybe<Array<Maybe<ICouponRuleInput>>>,
  locationIds?: Maybe<Array<Maybe<Scalars['String']>>>,
  tripIds?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdateLocationInput = {
  locationId: Scalars['ID'],
  title: Scalars['String'],
  country: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  image?: Maybe<Scalars['String']>,
  included?: Maybe<Array<Maybe<Scalars['String']>>>,
  notIncluded?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdatePolicyInput = {
  policy: Scalars['String'],
};

export type IUpdateTravelerInput = {
  travelerId: Scalars['ID'],
  firstName: Scalars['String'],
  lastName: Scalars['String'],
  birthday: Scalars['String'],
  gender: IGender,
  email: Scalars['String'],
  phone: Scalars['String'],
  passport?: Maybe<IPassportInput>,
  address: IAddressInput,
};

export type IUpdateTripInput = {
  tripId: Scalars['ID'],
  startDate: Scalars['String'],
  endDate: Scalars['String'],
  maxSeats: Scalars['Int'],
  detailsUrl?: Maybe<Scalars['String']>,
  active: Scalars['Boolean'],
  archived?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type IUpdateTripSkuInput = {
  tripId: Scalars['ID'],
  sku: Scalars['ID'],
  price: Scalars['Int'],
};

export type IListBookingsQueryVariables = {
  pageToken?: Maybe<Scalars['String']>,
  pageSize?: Maybe<Scalars['Int']>,
  query?: Maybe<Scalars['String']>
};


export type IListBookingsQuery = { bookingPage: Maybe<(
    Pick<IBookingPage, 'nextPageToken'>
    & { bookings: Maybe<Array<(
      Pick<IBooking, 'bookingId' | 'tripId' | 'status' | 'returnCustomer' | 'roomArrangement' | 'friendName' | 'createTime' | 'updateTime'>
      & { travelers: Maybe<Array<Pick<ITraveler, 'firstName' | 'lastName'>>>, trip: Maybe<(
        Pick<ITrip, 'startDate'>
        & { location: Maybe<Pick<ILocation, 'title' | 'country'>> }
      )> }
    )>> }
  )> };

export type IUpdateBookingMutationVariables = {
  input: IUpdateBookingInput
};


export type IUpdateBookingMutation = { updateBooking: Maybe<Pick<IBooking, 'bookingId' | 'status' | 'updateTime'>> };

export type ICreateOrderPriceAdjustmentMutationVariables = {
  input: ICreateOrderPriceAdjustmentInput
};


export type ICreateOrderPriceAdjustmentMutation = { createOrderPriceAdjustment: Maybe<Pick<IOrderPriceAdjustment, 'id' | 'amount' | 'note' | 'createTime'>> };


export const ListBookingsDocument = gql`
    query ListBookings($pageToken: String, $pageSize: Int, $query: String) {
  bookingPage(pageToken: $pageToken, pageSize: $pageSize, query: $query) {
    nextPageToken
    bookings {
      bookingId
      tripId
      status
      returnCustomer
      roomArrangement
      friendName
      createTime
      updateTime
      travelers {
        firstName
        lastName
      }
      trip {
        startDate
        location {
          title
          country
        }
      }
    }
  }
}
    `;

/**
 * __useListBookingsQuery__
 *
 * To run a query within a React component, call `useListBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBookingsQuery({
 *   variables: {
 *      pageToken: // value for 'pageToken'
 *      pageSize: // value for 'pageSize'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useListBookingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IListBookingsQuery, IListBookingsQueryVariables>) {
        return ApolloReactHooks.useQuery<IListBookingsQuery, IListBookingsQueryVariables>(ListBookingsDocument, baseOptions);
      }
export function useListBookingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IListBookingsQuery, IListBookingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IListBookingsQuery, IListBookingsQueryVariables>(ListBookingsDocument, baseOptions);
        }
export type ListBookingsQueryHookResult = ReturnType<typeof useListBookingsQuery>;
export type ListBookingsLazyQueryHookResult = ReturnType<typeof useListBookingsLazyQuery>;
export type ListBookingsQueryResult = ApolloReactCommon.QueryResult<IListBookingsQuery, IListBookingsQueryVariables>;
export const UpdateBookingDocument = gql`
    mutation UpdateBooking($input: UpdateBookingInput!) {
  updateBooking(input: $input) {
    bookingId
    status
    updateTime
  }
}
    `;
export type IUpdateBookingMutationFn = ApolloReactCommon.MutationFunction<IUpdateBookingMutation, IUpdateBookingMutationVariables>;

/**
 * __useUpdateBookingMutation__
 *
 * To run a mutation, you first call `useUpdateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingMutation, { data, loading, error }] = useUpdateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IUpdateBookingMutation, IUpdateBookingMutationVariables>) {
        return ApolloReactHooks.useMutation<IUpdateBookingMutation, IUpdateBookingMutationVariables>(UpdateBookingDocument, baseOptions);
      }
export type UpdateBookingMutationHookResult = ReturnType<typeof useUpdateBookingMutation>;
export type UpdateBookingMutationResult = ApolloReactCommon.MutationResult<IUpdateBookingMutation>;
export type UpdateBookingMutationOptions = ApolloReactCommon.BaseMutationOptions<IUpdateBookingMutation, IUpdateBookingMutationVariables>;
export const CreateOrderPriceAdjustmentDocument = gql`
    mutation CreateOrderPriceAdjustment($input: CreateOrderPriceAdjustmentInput!) {
  createOrderPriceAdjustment(input: $input) {
    id
    amount
    note
    createTime
  }
}
    `;
export type ICreateOrderPriceAdjustmentMutationFn = ApolloReactCommon.MutationFunction<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>;

/**
 * __useCreateOrderPriceAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateOrderPriceAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderPriceAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderPriceAdjustmentMutation, { data, loading, error }] = useCreateOrderPriceAdjustmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderPriceAdjustmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>) {
        return ApolloReactHooks.useMutation<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>(CreateOrderPriceAdjustmentDocument, baseOptions);
      }
export type CreateOrderPriceAdjustmentMutationHookResult = ReturnType<typeof useCreateOrderPriceAdjustmentMutation>;
export type CreateOrderPriceAdjustmentMutationResult = ApolloReactCommon.MutationResult<ICreateOrderPriceAdjustmentMutation>;
export type CreateOrderPriceAdjustmentMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateOrderPriceAdjustmentMutation, ICreateOrderPriceAdjustmentMutationVariables>;