import { Button, Select, Tooltip, Typography } from 'antd';
import { ApolloError } from 'apollo-client';
import { Field, FieldProps, Form, Formik } from 'formik';
import { GraphQLError } from 'graphql';
import * as L from 'partial.lenses';
import * as R from 'ramda';
import { default as React, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import { IBooking, ListBookingsDocument, useUpdateBookingMutation } from '../../../generated/graphql';

interface Props {
  children: any;
  booking: IBooking;
  editable?: boolean;
}

function EditableCell(props: Props) {
  const { booking, editable, children, ...rest } = props;
  const [search = ''] = useQueryParam('search', StringParam);
  const [editing, setEditing] = useState(false);
  const [updateBooking] = useUpdateBookingMutation();
  const pageSize = 25;

  const onSubmit = useCallback(
    async (values: any, formikActions: any) => {
      try {
        formikActions.setSubmitting(true);
        formikActions.setStatus(undefined);

        await updateBooking({
          variables: {
            input: {
              bookingId: booking.bookingId,
              tripId: booking.tripId,
              returnCustomer: booking.returnCustomer!,
              roomArrangement: booking.roomArrangement!,
              status: values.status,
              friendName: booking.friendName
            }
          },
          update: (store, { data = { updateBooking: {} } }) => {
            const saved = store.readQuery({ query: ListBookingsDocument, variables: { query: search, pageSize } });
            store.writeQuery({
              query: ListBookingsDocument,
              variables: { query: search, pageSize },
              data: L.modify(
                ['bookingPage', 'bookings', L.find(R.whereEq({ bookingId: booking.bookingId }))],
                (d: IBooking) => ({ ...d, ...data!.updateBooking })
              )(saved)
            });
          }
        });

        setEditing(false);
      } catch (err) {
        console.error(err);
        const message = (err as ApolloError).graphQLErrors.map((error: GraphQLError) => error.message).join(', ');
        formikActions.setStatus(message);
      } finally {
        formikActions.setSubmitting(false);
      }
    },
    [booking, search, updateBooking]
  );

  return (
    <td {...rest}>
      {editable ? (
        editing ? (
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              status: booking.status
            }}
            render={formikProps => (
              <Form>
                <Field
                  name="status"
                  render={({ field, form }: FieldProps<any>) => (
                    <Select
                      disabled={formikProps.isSubmitting}
                      onChange={(value: string) => form.setFieldValue(field.name, value)}
                      value={field.value}
                      optionFilterProp="children"
                      filterOption={(input: string, option: any) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    >
                      {R.map((status: string) => (
                        <Select.Option key={status} value={status}>
                          {status}
                        </Select.Option>
                      ))(['APPROVED', 'PENDING', 'CANCELLED_REFUNDED', 'CANCELLED_HELD'])}
                    </Select>
                  )}
                />
                <Tooltip title="Cancel">
                  <Button
                    disabled={formikProps.isSubmitting}
                    htmlType="button"
                    type="link"
                    icon="close-circle"
                    onClick={() => setEditing(false)}
                  />
                </Tooltip>
                <Tooltip title="Save">
                  <Button loading={formikProps.isSubmitting} htmlType="submit" type="link" icon="check-circle" />
                </Tooltip>
                {!!formikProps.status && <Typography.Text type="danger">{formikProps.status}</Typography.Text>}
              </Form>
            )}
          />
        ) : (
          <HoverCell onClick={() => setEditing(true)}>{children}</HoverCell>
        )
      ) : (
        children
      )}
    </td>
  );
}

const HoverCell = styled('section')`
  padding: 4px 11px;
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #d9d9d9;
  }
`;

export default EditableCell;
