import gql from 'graphql-tag';

export interface ICoupon {
  couponId: string;
  description: string;
  startTime: string;
  endTime: string;
  maxRedemptions: number;
  global: boolean;
  disabled: boolean;
  deletedTime: string;
  locationIds: Array<string>;
  locations: Array<ILocation>;
  tripIds: Array<string>;
  trips: Array<ITrip>;
  rules?: Array<ICouponRule>;
}

export interface ICouponRule {
  rules?: Array<ICouponRule>;
}

export interface ICouponRule {
  type: string;
  value: number;
  conditions: Array<ICouponRuleCondition>;
}

export interface ICouponRuleCondition {
  field: string;
  operator: string;
  value: string;
}

export interface ILocation {
  locationId: string;
  title: string;
}

export interface ITrip {
  tripId: string;
  startDate: string;
  location: {
    title: string;
  };
}

export interface GetCouponResponse {
  coupon: ICoupon;
}

export const GET_COUPON = gql`
  query coupon($couponId: ID!) {
    coupon(couponId: $couponId) {
      couponId
      description
      startTime
      endTime
      maxRedemptions
      global
      disabled
      deletedTime
      locationIds
      locations {
        locationId
        title
      }
      tripIds
      trips {
        tripId
        location {
          title
        }
        startDate
      }
      rules {
        type
        value
        conditions {
          field
          operator
          value
        }
      }
    }
  }
`;

export const UPDATE_COUPON = gql`
  mutation updateCoupon($input: UpdateCouponInput!) {
    updateCoupon(input: $input) {
      couponId
      description
      startTime
      endTime
      maxRedemptions
      global
      disabled
      deletedTime
      locationIds
      locations {
        locationId
        title
      }
      tripIds
      trips {
        tripId
        location {
          title
        }
        startDate
      }
      rules {
        type
        value
        conditions {
          field
          operator
          value
        }
      }
      createTime
      updateTime
    }
  }
`;

export const DELETE_COUPON = gql`
  mutation deleteCoupon($couponId: ID!) {
    deleteCoupon(couponId: $couponId)
  }
`;
