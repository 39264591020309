import gql from 'graphql-tag';

export interface CreateLocationResponse {
  createLocation: {
    locationId: string;
  };
}

export const CREATE_LOCATION = gql`
  mutation createLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      locationId
    }
  }
`;
