import gql from 'graphql-tag';

export interface ITrip {
  tripId: string;
  locationId: string;
  startDate: string;
  endDate: string;
  maxSeats: number;
  detailsUrl: string;
  active: boolean;
  archived: boolean;
  tags: Array<string>;
  skus: Array<ISKU>;
  location: ILocation;
}

export interface ILocation {
  locationId: string;
  country: string;
  title: string;
}

export interface ISKU {
  sku: string;
  type: string;
  price: number;
}

export interface GetTripResponse {
  trip: ITrip;
}

export const GET_TRIP = gql`
  query trip($tripId: ID!) {
    trip(tripId: $tripId) {
      tripId
      locationId
      startDate
      endDate
      maxSeats
      detailsUrl
      active
      archived
      tags
      skus {
        sku
        type
        price
      }
      location {
        locationId
        country
        title
      }
    }
  }
`;

export interface UpdateTripResponse {
  updateTrip: {
    tripId: string;
    locationId: string;
    startDate: string;
    endDate: string;
    maxSeats: number;
    detailsUrl: string;
    active: boolean;
    tags: Array<string>;
  };
}

export const UPDATE_TRIP = gql`
  mutation updateTrip($input: UpdateTripInput!) {
    updateTrip(input: $input) {
      tripId
      locationId
      startDate
      endDate
      maxSeats
      detailsUrl
      active
      tags
    }
  }
`;

export interface UpdateTripSKUResponse {
  updateTripSKU: {
    sku: string;
  };
}

export const UPDATE_TRIP_SKU = gql`
  mutation updateTripSKU($input: UpdateTripSKUInput!) {
    updateTripSKU(input: $input) {
      sku
    }
  }
`;
