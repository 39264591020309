import gql from 'graphql-tag';

interface ILocation {
  locationId: string;
  country: string;
  title: string;
  description: string;
  included: Array<string>;
  notIncluded: Array<string>;
  image: string;
}

export interface GetLocationResponse {
  location: ILocation;
}

export const GET_LOCATION = gql`
  query location($locationId: ID!) {
    location(locationId: $locationId) {
      locationId
      country
      title
      description
      included
      notIncluded
      image
    }
  }
`;

export interface UpdateLocationResponse {
  updateLocation: {
    locationId: string;
  };
}

export const UPDATE_LOCATION = gql`
  mutation updateLocation($input: UpdateLocationInput) {
    updateLocation(input: $input) {
      locationId
    }
  }
`;
