import gql from 'graphql-tag';

export interface ILocation {
  locationId: string;
  country: string;
  title: string;
}

export interface ListLocationsResponse {
  locations: Array<{
    locationId: string;
    country: string;
    title: string;
  }>;
}

export const LIST_LOCATIONS = gql`
  {
    locations {
      locationId
      country
      title
    }
  }
`;

export interface CreateTripResponse {
  createTrip: {
    tripId: string;
  };
}

export const CREATE_TRIP = gql`
  mutation createTrip($input: CreateTripInput!) {
    createTrip(input: $input) {
      tripId
    }
  }
`;
