import { Button, Form, Select } from 'antd';
import { message } from 'antd/es';
import { useFormik } from 'formik';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo-hooks';
import { RouteComponentProps } from 'react-router';
import Alert from '../../components/Alert';
import Layout from '../../components/Layout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import LocationForm from '../../components/LocationForm/LocationForm';
import { FormProvider } from '../Coupon/components/Form';
import { GET_LOCATION, GetLocationResponse, UPDATE_LOCATION, UpdateLocationResponse } from './Edit.graphql';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 20,
      offset: 4
    }
  }
};

interface Props extends RouteComponentProps<{ locationId: string }> {}

function Edit(props: Props) {
  const { locationId } = props.match.params;
  const query = useQuery<GetLocationResponse>(GET_LOCATION, { variables: { locationId } });
  const [updateLocation, { loading, error }] = useMutation<UpdateLocationResponse>(UPDATE_LOCATION);
  const removeTypenameField = R.omit(['__typename']);

  const location = query.data ? query.data.location : undefined;

  const formik = useFormik<any>({
    initialValues: location || {},
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        const input = R.pipe(removeTypenameField)(values);

        await updateLocation({
          variables: {
            input
          }
        });
        message.success('Location saved successfully!');
      } catch (err) {
        message.error('Location save failed!');
      }
    }
  });

  if (query.loading) {
    return <LoadingSpinner />;
  }

  if (query.error) {
    return <div>{query.error.message}</div>;
  }

  return (
    <Layout>
      <FormProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <LocationForm />

          <Form.Item {...formItemLayout} label="Coupons">
            <Select
              mode="tags"
              value={formik.values.notIncluded}
              onChange={(values: any) => formik.setFieldValue('coupons', values)}
              size="large"
            >
              {R.pipe<Array<string>, Array<string>, Array<React.ReactElement<any>>>(
                R.defaultTo(RA.stubArray()),
                R.map((text: string) => (
                  <Select.Option key={text} value={text}>
                    {text}
                  </Select.Option>
                ))
              )(formik.values.notIncluded)}
            </Select>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Form.Item>

          <Alert error={error} />
        </form>
      </FormProvider>
    </Layout>
  );
}

export default Edit;
