import { Alert, Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import FlexSearch from 'flexsearch';
import { GraphQLError } from 'graphql';
import * as RA from 'ramda-adjunct';
import * as React from 'react';
import { useMemo } from 'react';
import { useQuery } from 'react-apollo-hooks';
import { useQueryParam } from 'use-query-params';
import { StringParam } from 'use-query-params/lib/params';
import links from '../../lib/links';
import { ICoupon, LIST_COUPONS, ListCouponsResponse } from './List.graphql';

const columns = [
  {
    title: 'Coupon',
    dataIndex: 'couponId',
    key: 'couponId'
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description'
  },
  {
    title: 'Is Global',
    dataIndex: 'global',
    key: 'global',
    render: (text: string, coupon: ICoupon) => <span>{coupon.global ? 'Yes' : 'No'}</span>
  },
  {
    title: 'Disabled',
    dataIndex: 'disabled',
    key: 'disabled',
    render: (text: string, coupon: ICoupon) => <span>{coupon.disabled ? 'Yes' : 'No'}</span>
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (text: string, coupon: ICoupon) => (
      <section>
        <Tooltip title="Edit">
          <Button icon="form" type="link" href={links.editCoupon(coupon.couponId)} />
        </Tooltip>
      </section>
    )
  }
];

function List() {
  const [search = '', setSearch] = useQueryParam('search', StringParam);
  const query = useQuery<ListCouponsResponse>(LIST_COUPONS);

  const coupons = query.data ? query.data.coupons : [];

  const index = useMemo(() => {
    return FlexSearch.create({
      doc: {
        id: 'couponId',
        field: [
          'couponId',
          'description',
          'startTime',
          'endTime',
          'global',
          'disabled',
          'maxRedemptions',
          'createTime',
          'updateTime'
        ]
      }
    } as any).add(coupons);
  }, [coupons]);

  const errors = useMemo(() => {
    return (query.errors || []).map((error: GraphQLError, i: number) => (
      <Alert key={i} message={error.message} type="error" />
    ));
  }, [query.errors]);

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        href={links.createCoupon()}
        style={{
          marginBottom: 16
        }}
      >
        Create Coupon
      </Button>
      <Search
        name="search"
        size="large"
        onSearch={value => setSearch(value)}
        style={{
          marginBottom: '1rem'
        }}
      />
      <Table
        loading={query.loading}
        columns={columns}
        dataSource={RA.isNotEmpty(search) ? index.search(search) : coupons}
        rowKey="couponId"
        bordered
      />
      <br />
      {!!query.error && <Alert message={query.error.message} type="error" />}
      {errors}
    </>
  );
}

export default List;
