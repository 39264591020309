import gql from 'graphql-tag';

export interface ICoupon {
  couponId: string;
  description: string;
  startTime: string;
  endTime: string;
  global: boolean;
  disabled: boolean;
  maxRedemptions: number;
  createTime: string;
  updateTime: string;
}

export interface ListCouponsResponse {
  coupons: Array<ICoupon>;
}

export const LIST_COUPONS = gql`
  query coupons {
    coupons {
      couponId
      description
      startTime
      endTime
      global
      disabled
      maxRedemptions
      createTime
      updateTime
    }
  }
`;
